<template>
  <div
    class="mm-basket-warning-row"
    :class="{
      'mm-basket-warning-row--info': warningTypeIsInfo,
      'mm-basket-warning-row--simple': warningTypeIsSimple,
    }"
  >
    <slot name="icon">
      <SvgIcon
        :src="svgIcon"
        :class="{ 'icon-on-top' : iconOnTop }"
        class="mm-basket-warning-row__image"
      />
    </slot>

    <span
      id="warning_message"
      class="mm-basket-warning-row__text"
    >
      <slot>
        <span v-html="sanitize(text)" />
      </slot>
    </span>

    <button
      v-if="!hideSelectButton"
      id="choose_solution_button"
      class="btn mm-basket-warning-row__button"
      :class="{
        'btn-primary': warningTypeIsSimple || buttonType === 'primary',
        'btn-text': !warningTypeIsSimple || buttonType === 'text',
        disabled: disabledButton,
        'pointer-events': disabledButton && errorMessage,
      }"
      @click="onClickButton"
    >
      <slot name="buttonText">
        {{ buttonText }}
      </slot>

      <SvgIcon
        v-if="!hideButtonIcon"
        class="mm-basket-warning-row__button-icon"
        src="navigation/arrow-right-20px"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from 'shared/components/SvgIcon.vue';
import { sanitize } from 'shared/directives/sanitize';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { EWarningMessageType } from 'shared/enums/warningMessageType.enum';
import Notificator from 'shared/services/notificator.service';

const props = withDefaults(
  defineProps<{
    hideSelectButton?: boolean;
    hideButtonIcon?: boolean;
    text?: string;
    buttonText?: string;
    disabledButton?: boolean;
    errorMessage?: string;
    iconOnTop?: boolean; // принудительно сделать иконку в верхнем левом углу
    warningType?: EWarningMessageType;
    buttonType?: 'primary' | 'text'
  }>(),
  {
    buttonText: 'Выбрать решение',
    warningType: EWarningMessageType.Simple,
    text: '',
  },
);

const emit = defineEmits<{
  (e: 'clickChooseSolution'): void;
}>();

const iconsByWarningTypeMap = new Map<EWarningMessageType, EIconPath>([
  [EWarningMessageType.Warning, EIconPath.NavigationInfo],
  [EWarningMessageType.Info, EIconPath.NavigationInfoOrangeSvg],
  [EWarningMessageType.Simple, EIconPath.NavigationInfoFillSvg],
]);

const notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined;

const warningTypeIsInfo = computed(() => props.warningType === EWarningMessageType.Info);
const warningTypeIsSimple = computed(() => props.warningType === EWarningMessageType.Simple);

function onClickButton(): void {
  if (props.errorMessage && props.disabledButton) {
    notificator?.showNotification(props.errorMessage);
  }

  if (props.disabledButton) {
    return;
  }

  emit('clickChooseSolution');
}

const svgIcon = computed(() => iconsByWarningTypeMap.get(props.warningType) || EIconPath.NavigationInfo);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-basket-warning-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $basket-warning-row-bg;
  padding: 16px;
  border-radius: 8px;

  &.mm-basket-warning-row--info {
    background-color: $basket-warning-row-info;
  }

  &__image {
    margin-right: 12px;

    &.icon-on-top {
      margin-top: -16px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    text-align: left;
    color: $basket-warning-row-text-c;

    > span > span {
      font-weight: 500 !important;
    }
  }

  &__button {
    padding: 0;
    font-weight: 500;

    &-icon {
      width: 20px;
    }

    &.disabled {
      color: $text-disabled;
      :deep(path) {
        fill: $text-disabled;
      }
    }

    &.pointer-events {
      pointer-events: unset;
    }

    &.btn-primary {
      padding: 10px 28px;
      color: $text-white;
    }
  }

  &--simple {
    background-color: $light-gray;
  }

  &--simple &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $text-dark-green;
  }

  &--simple &__image {
    :deep(circle) {
      fill: $light-green;
    }

    :deep(path) {
      fill: $text-white;
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-basket-warning-row {
    min-height: 72px;
    position: relative;

    &__image {
      margin-top: -16px;
    }
  }
}
</style>
